import axios from "axios";


export const apiService = async (type, link, formData, header) => {
  // const dispatch = useDispatch()
  // const [state, setState] = useState()
  let urlConst = "https://gooya.net:8080/";

  let apiUrl = "";
  apiUrl = urlConst + link;
  // try {
  let response;
  if (type === "GET") {
    response = await axios.get(apiUrl, {
      headers: header
    });

    return response;
  }
  if (type === "POST") {
    response = await axios.post(apiUrl, formData, {
      headers: header,
    });
    
    return response;
  }

};
