import { IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';

function Footer() {
  return (
    <div>
        
      <Stack direction={{xs:"column",md:"row"}} justifyContent={{md:"space-around", xs:"center"}} sx={{background: '#100e26', alignItems:"center",
          color: 'white',
          padding: '40px 30px 10px 30px',
          borderTop:"1px solid white"}}>
        <Stack direction="row" spacing={2} >
            <Typography>
                Copyright
            </Typography>
            <Typography>
                2010-2023 Gooya Company All rights reserved.
            </Typography>

        </Stack>
        <Stack direction="row" alignItems={"flex-end"} >
            <IconButton  sx={{color:"white"}}>
                <InstagramIcon fontSize="large"/>
            </IconButton>
            <IconButton sx={{color:"white", fontSize:50}}>
                <FacebookOutlinedIcon fontSize="large"/>
            </IconButton>
            <IconButton sx={{color:"white", fontSize:50}}>
                <TwitterIcon fontSize="large"/>
            </IconButton>
        </Stack>


      </Stack>
    </div>
  )
}

export default Footer
