import {
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { validationSchema } from "../Login/Constant";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { apiService } from "../../HTTP/api";
import { apiConst } from "../../HTTP/Http";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useNavigate } from "react-router-dom";
import { LoginContext } from "../../App";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Login(props) {
  const { open, onClose } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();
  const loginCtx = useContext(LoginContext);
  const [openAlert, setOpenalert] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenalert(false);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const {
          data: { data, status },
        } = await apiService("POST", apiConst.login, values, {
          Authorization:
            "eyJpdiI6ImJieitMQ2QrRTF4Q2xIMUN1RTFKUmc9PSIsInZhbHVlIjoiUUMxWGZMT29aUEJIK0FLU0F6emNNM0pWZVFCd0ltNEwxVEdJZDZoQ0k1bz0iLCJtYWMiOiJkNzc0ZWMzMDA3NzcyMjY1OWFlOWM3MDk2ZDYwNzJjMWRjMmI0ODMzYjFiMmExZTljYTgyOWEzM2U1ZTNhMGQ1In0=",
        });

        if (status === 200) {
          localStorage.setItem("accessToken", data.access_token);
          localStorage.setItem("userData", JSON.stringify(data));
          localStorage.setItem("isLoggedIn", true);
          onClose();

          setOpenalert(true);
          
          navigate("/makeCallRequest");
          
        } else {
          setAlertMsg("Something went wrong");
          setSeverity("error");
        }
        setOpenalert(true);
      } catch (e) {
        setSeverity("error");
        setAlertMsg(e.response.data.message);

        setOpenalert(true);
      } finally {
        formik.resetForm();
      }
    },
  });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Card className="login-card" elevation={0}>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack
            gap={2}
            direction="column"
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <IconButton
              disableFocusRipple
              disableRipple
              sx={{ position: "absolute", top: "0px", right: "0px" }}
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5">Welcome Back</Typography>

            <Stack
              direction="column"
              sx={{
                width: "100%",
                alignSelf: "center",

                padding: "2px",
              }}
              spacing={4}
            >
              <TextField
                id="outlined-basic"
                placeholder="Email or Account Username"
                variant="outlined"
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
              />
              <TextField
                id="outlined-basic"
                placeholder="Password"
                variant="outlined"
                name="password"
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Button fullWidth variant="contained" color="error" type="submit">
              Login
            </Button>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: "100%" }}
              >
                {alertMsg}
              </Alert>
            </Snackbar>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}

export default Login;
