import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import Approutes from "./Routes/Approutes";
import { Button, IconButton } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

import CloseIcon from "@mui/icons-material/Close";

export const LoginContext = createContext(null);

function App() {
  let deferredPrompt;

  const [isStandalone, setIsStandalone] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(display-mode: standalone)");

    // Initial check
    setIsStandalone(mediaQuery.matches);

    // Listen for changes in the display mode
    const handleChange = (event) => {
      setIsStandalone(event.matches);
    };

    mediaQuery.addEventListener("change", handleChange);

    const mobileMediaQuery = window.matchMedia("(max-width: 768px)"); // Adjust the width as needed

    // Initial check
    setIsMobile(mobileMediaQuery.matches);

    // Listen for changes in the media query
    const handleDeviceChange = (event) => {
      setIsMobile(event.matches);
    };

    mobileMediaQuery.addEventListener("change", handleDeviceChange);

    // Clean up the event listener

    // Clean up the event listener
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
      mobileMediaQuery.removeEventListener("change", handleDeviceChange);
    };
  }, []);

  const [showDownloadButton, setShowDownloadButton] = useState(true);

  window.addEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    deferredPrompt = event;
  });
  console.log(isStandalone);
  const handleDownload = () => {
    console.log(deferredPrompt);
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          setShowDownloadButton(false);
          console.log("User dismissed the install prompt");
        }
        deferredPrompt = null;
      });
    } else {
      setShowDownloadButton(false);
    }
  };

  const action = (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Button color="primary" size="small" onClick={handleDownload}>
        Install
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setShowDownloadButton(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );

  // Event listener for beforeinstallprompt
  console.log(showDownloadButton && !isStandalone && isMobile);
  return (
    <>
      <Approutes />
      <Snackbar
        open={showDownloadButton && !isStandalone && isMobile}
        onClose={() => setShowDownloadButton(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message="Add to home screen"
        action={action}
      />
    </>
  );
}

export default App;
