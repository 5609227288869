import { Button, Divider, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
function Offers() {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Get the image element's bounding rectangle
      const image = document.getElementById("animatedBlock");
      const imageRect = image.getBoundingClientRect();

      // Check if the image is in the viewport
      if (imageRect.top + 100 < window.innerHeight && imageRect.bottom >= 0) {
        setAnimated(true);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scollToContact = () => {
    const element = document.getElementById("contactUS");
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className="offerSection">
      <div className="offerName">LIMITED OFFERS FOR NEW USERS ONLY</div>

      {/* offer section */}

      <Stack
        direction={{ xs: "column", md: "row" }}
        divider={
          <Divider
            orientation={{ xs: "horizontal", md: "vertical" }}
            flexItem
          />
        }
        justifyContent="space-evenly"
        sx={{
          mx: { xs: 5, md: 18 },
          mt: 10,
          fontWeight: 300,
          rowGap: { xs: "20px", md: "0px" },
        }}
      >
        {/* Content for Afghanistan */}
        <Stack
          spacing={2}
          sx={{
            textAlign: { xs: "center", md: "left" },
            flexDirection: { xs: "column", sm: "row", md: "column" },
          }}
          justifyContent={{ xs: "space-between" }}
          alignItems={"center"}
        >
          <Stack
            spacing={{ xs: 1, md: 2 }}
            sx={{
              textAlign: { xs: "left", md: "center" },
              flexDirection: "column",
            }}
          >

            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                columnGap: "4px",
              }}
            >
              <span className="bgaf"> </span>
              <Typography variant={"h6"}>Afghanistan</Typography>
            </Stack>
            <Typography>Try 0.099 Per Min</Typography>
          </Stack>

          <Button
            variant="outlined"
            color="error"
            sx={{ width: { xs: "80%", sm: "unset" } }}
            onClick={scollToContact}
          >
            CONTACT US
          </Button>
        </Stack>

        {/* Content for Nigeria */}
        <Stack
          spacing={2}
          sx={{
            textAlign: { xs: "center", md: "left" },
            flexDirection: { xs: "column", sm: "row", md: "column" },
          }}
          justifyContent={{ xs: "space-between" }}
          alignItems={"center"}
        >
          <Stack
            spacing={{ xs: 1, md: 2 }}
            sx={{
              textAlign: { xs: "left", md: "center" },
              flexDirection: "column",
            }}
          >
             <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                columnGap: "4px",
              }}
            >
              <span className="bgng"> </span>
              <Typography variant={"h6"}>Nigeria</Typography>
            </Stack>
            <Typography>Try 0.079 Per Min</Typography>
            
          </Stack>

          <Button
            variant="outlined"
            color="error"
            sx={{ width: { xs: "80%", sm: "unset" } }}
            onClick={scollToContact}
          >
            CONTACT Us
          </Button>
        </Stack>
        {/* Content for Iran*/}
        <Stack
          spacing={2}
          sx={{
            textAlign: { xs: "center", md: "left" },
            flexDirection: { xs: "column", sm: "row", md: "column" },
          }}
          justifyContent={{ xs: "space-between" }}
          alignItems={"center"}
        >
          <Stack
            spacing={{ xs: 1, md: 2 }}
            sx={{
              textAlign: { xs: "left", md: "center" },
              flexDirection: "column",
            }}
          >
             <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                columnGap: "4px",
              }}
            >
              <span className="bgir"> </span>
              <Typography variant={"h6"}>Iran</Typography>
            </Stack>
            <Typography>Try 0.099 Per Min</Typography>
          </Stack>

          <Button
            variant="outlined"
            color="error"
            sx={{ width: { xs: "80%", sm: "unset" } }}
            onClick={scollToContact}
          >
            CONTACT Us
          </Button>
        </Stack>

        {/* Content for Turkey*/}
        <Stack
          spacing={2}
          sx={{
            textAlign: { xs: "center", md: "left" },
            flexDirection: { xs: "column", sm: "row", md: "column" },
          }}
          justifyContent={{ xs: "space-between" }}
          alignItems={"center"}
        >
          <Stack
            spacing={{ xs: 1, md: 2 }}
            sx={{
              textAlign: { xs: "left", md: "center" },
              flexDirection: "column",
            }}
          >
           <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                columnGap: "4px",
              }}
            >
              <span className="bgtr"> </span>
              <Typography variant={"h6"}>Turkey</Typography>
            </Stack>
            <Typography>Try 0.099 Per Min</Typography>
          </Stack>

          <Button
            variant="outlined"
            color="error"
            sx={{ width: { xs: "80%", sm: "unset" } }}
            onClick={scollToContact}
          >
            CONTACT Us
          </Button>
        </Stack>

        {/* Content for Yemen*/}
        <Stack
          spacing={2}
          sx={{
            textAlign: { xs: "center", md: "left" },
            flexDirection: { xs: "column", sm: "row", md: "column" },
          }}
          justifyContent={{ xs: "space-between" }}
          alignItems={"center"}
        >
          <Stack
            spacing={{ xs: 1, md: 2 }}
            sx={{
              textAlign: { xs: "left", md: "center" },
              flexDirection: "column",
            }}
          >
              <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                columnGap: "4px",
              }}
            >
              <span className="bgye"> </span>
              <Typography variant={"h6"}>Yemen</Typography>
            </Stack>
            <Typography>Try 0.099 Per Min</Typography>
          </Stack>

          <Button
            variant="outlined"
            color="error"
            sx={{ width: { xs: "80%", sm: "unset" } }}
            onClick={scollToContact}
          >
            CONTACT Us
          </Button>
        </Stack>

      </Stack>

      {/* how it works section */}

      <Stack
        sx={{
          display: "flex",
          padding: "0px 50px",
          flexDirection: { xs: "column", md: "row" },
        }}
        id={"animatedBlock"}
      >
        <Stack
          sx={{
            mt: 15,
            transform: animated ? "translateX(0)" : "translateX(-10%)",
            transition: "transform 1s ease",
            overflowX: "hidden",
          }}
        >
          {/* image */}

          <img
            alt="mobile"
            src="https://rebtel.imgix.net/upload/v2/pages/product-pages/world-credits/credits-phone.png?auto=format%2Ccompress&q=50"
          ></img>
        </Stack>
        {/* detail */}
        <Stack
          sx={{
            mt: 25,
            ml: 5,
            transform: animated ? "translateX(0)" : "translateX(10%)",
            transition: "transform 1s ease",
            overflowX: "hidden",
          }}
        >
          <Typography sx={{ color: "#e3232c" }}>
            ONECLICK INTERNATIONAL CALLING
          </Typography>
          <Typography
            sx={{
              fontSize: "42px",
              lineHeight: "48px",
              mt: "10px",
            }}
          >
            How does Gooya work?
          </Typography>
          <Typography
            sx={{
              color: "#575757",
              fontSize: "20px",
              lineHeight: "32px",
              letterSpacing: "1px",
              mt: "20px",
            }}
          >
            We make international calling simple, reliable, and cheap based on
            your unique calling behavior.
          </Typography>
          <Button
            sx={{ width: 150, height: 50, mt: 5 }}
            variant="contained"
            color="error"
            onClick={scollToContact}
          >
            Contact US
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}

export default Offers;
