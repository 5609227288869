import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import React,{useState,useEffect} from 'react'
import Turkey from "../../media/Mask Turkey.jpg"
import Iran from "../../media/Iran.jpg"
import Afghanistan from "../../media/Afghanistan.jpg"
import Yemen from "../../media/Yemen.jpg"
import Nigeria from "../../media/Nigeria.jpg"

function Callfamily() {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    // Set animated to true after a short delay to trigger the animation
    const handleScroll = () => {
      // Get the image element's bounding rectangle
      const image = document.getElementById('callFamily');
      const imageRect = image.getBoundingClientRect();
      
      // Check if the image is in the viewport
      if (imageRect.top + 200< window.innerHeight && imageRect.bottom >= 0) {
        setAnimated(true);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const inputRootStyle = {
    borderRadius: '50px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    // padding: '8px 12px',
  }
  return (
    <Stack spacing={4} id={"callFamily"}  sx={{
      
      width:"100%", backgroundColor:"#090c26", color:"white" , pt:"80px", pb:"50px"}} alignItems={"center"}> 
    {/* text */}
      <Stack sx={{fontSize:{xs:"20px",md:"30px"}}}>Call family and friends all over the world.</Stack>
      {/* \search box */}
      {/* <Stack>
        <Autocomplete
          sx={{ width: { xs: '300px', sm: 450 } }}
          // options={country}
          renderInput={(params) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              {...params}
              placeholder="Where do you want to call?"
              InputProps={{
                ...params.InputProps,
                style: inputRootStyle,
              }}
            />
          )}
        />
      </Stack> */}
      {/* countries */}
      <Stack direction="row"  sx={{transform: animated ? 'translateY(0)' : 'translateY(100%)',
        transition: 'transform 0.5s ease',pt:"40px", flexWrap:"wrap", justifyContent:"center", alignItems:"center", rowGap:"30px", columnGap:"40px"}}>
        <Stack  alignItems={"center"} spacing={2}>
            <img src={Turkey} alt="Turkey" style={{height:"90px",width:"90px", borderRadius:"50%"}}></img>
            <Typography>Turkey</Typography>
        </Stack>
        <Stack alignItems={"center"} spacing={2}>
        <img src={Iran} alt="Iran" style={{height:"90px",width:"90px", borderRadius:"50%"}}></img>
            <Typography>Iran</Typography>
        </Stack>
        <Stack alignItems={"center"} spacing={2}>
        <img src={Nigeria} alt="Nigeria" style={{height:"90px",width:"90px", borderRadius:"50%"}}></img>
            <Typography>Turkey</Typography>
        </Stack>
        <Stack alignItems={"center"} spacing={2}>
        <img src={Yemen} alt="Yemen" style={{height:"90px",width:"90px", borderRadius:"50%"}}></img>
            <Typography>Yemen</Typography>
        </Stack>
        <Stack alignItems={"center"} spacing={2}>
        <img src={Afghanistan} alt="Afghanistan" style={{height:"90px",width:"90px", borderRadius:"50%"}}></img>
            <Typography>Afghanistan</Typography> 
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Callfamily
