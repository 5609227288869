import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Main from "../Pages/Layout/Main";
import { LoginContext } from "../App";

const IsLoggedIn = () => {
  if (localStorage.getItem("isLoggedIn")) {
    return <Navigate to="/home" />;
  } else {
    return <Main />;
  }
};

export default IsLoggedIn;
