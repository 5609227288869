import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import {
  Autocomplete,
  Button,
  CardContent,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useFormik } from "formik";
import { apiService } from "../../HTTP/api";
import { apiConst } from "../../HTTP/Http";
import { validationSchema } from "../CallNumber/Constant";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import Callstatus from "../CallStatus/Callstatus";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function CallNumber() {
  const filter = createFilterOptions();

  const [cost, setcost] = useState("");
  const [fromNumber, setFromNumber] = useState([]);
  const [openAlert, setOpenalert] = React.useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [accType, setAccType] = useState("");
  const [acc, setAcc] = useState("")
  const [openCallStatus, setopenCallStatus] = useState(false)
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenalert(false);
  };

  const handleCloseCallStatus = () => setopenCallStatus(false);
  
  useEffect(() => {
    const acc_type = JSON.parse(localStorage.getItem("userData")).debit_type;
    if(acc_type === "Credit"){
      setAccType("Credit")
      setAcc("Real-Time Balance")
    }
    else{
      setAccType("Debit")
      setAcc("PrePaid Balance")
    }
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken");

      // balance api
      try {
        const response = await apiService(
          "GET",
          `${apiConst.avaialbleCredit}`,
          "",
          {
            Authorization: `${token}`,
          }
        );
        const costCredit = response.data.data.cost;

        setcost(Number(costCredit).toFixed(2));
        try {
          const numberResponse = await apiService(
            "GET",
            `${apiConst.fromNumbers}/${response.data.data.id}`,
            "",
            {
              Authorization: `${token}`,
            }
          );
          setFromNumber(numberResponse.data.data);
        } catch (e) {
          if (e.response.data.status === 401) {
            localStorage.clear();
            navigate("home");
          }
        }
      } catch (e) {
        if (e.response.data.status === 401) {
          localStorage.clear();
          navigate("home");
        }
      }
    };

    fetchData();
  }, []);
  
  const formik = useFormik({
    initialValues: {
      toNumber: "",
      fromNumber: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      try {
        setopenCallStatus(true);
        const token = localStorage.getItem("accessToken");
        const {
          data: { data, status },
        } = await apiService(
          "POST",
          apiConst.makeCallRequest,
          {
            to: values.toNumber,
            from: values.fromNumber.number,
          },
          {
            Authorization: token,
          }
        );

          setAlertMsg("Call request submitted successfully!");
          setSeverity("success");
          setopenCallStatus(false);
          setOpenalert(true);
       
      } catch (e) {
        if (e.response.data.status === 401) {
          localStorage.clear();
          navigate("home");
        }
        setAlertMsg(e.response.data.message);
        setopenCallStatus(false);
        setSeverity("error");
        setOpenalert(true);
      }
    },
  });

  return (
    <div id="callNumberId">
     <Callstatus open={openCallStatus} onClose={handleCloseCallStatus} values={formik.values}/>
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "transparent",
          height: "100%",
        }}
        elevation={0}
      >
        <CardContent
          sx={{
            border: "0.5px solid red",
            borderRadius: "15px",
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          <Stack gap={2} component="form" onSubmit={formik.handleSubmit}>
            <Stack
              direction="row"
              spacing={10}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                rowGap: "20px",
              }}
            >
              <Stack>
                <Typography  gutterBottom>
                {acc}
                </Typography>
                <Typography sx={{  fontWeight: 'bolder' }} gutterBottom>
                Account Type: {accType}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="h4">${cost}</Typography>
                
              </Stack>
            </Stack>

            <Autocomplete
              className="bg-set"
              disablePortal
              options={fromNumber}
              getOptionLabel={(option) => option.number || option.inputValue}
              id="combo-box-demo"
              sx={{ width: "100%", alignSelf: "center", bgcolor: "white" }}
              name="fromNumber"
              onChange={(e, newValue) => {
                console.log(newValue);
                if (newValue === null) {
                  formik.setFieldValue("fromNumber", "");
                } else if (newValue && newValue.inputValue) {
                  formik.setFieldValue("fromNumber", {
                    number: newValue.inputValue,
                  });
                 
                } else {
                  console.log(newValue);
                  formik.setFieldValue("fromNumber", newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;

                const isExisting = options.some(
                  (option) => inputValue === option.inputValue
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: inputValue,
                  });
                }

                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="fromNumber"
                  placeholder="Calling FROM Number"
                  value={formik.values.fromNumber}
                  error={
                    formik.touched.fromNumber &&
                    Boolean(formik.errors.fromNumber)
                  }
                  helperText={
                    formik.touched.fromNumber && formik.errors.fromNumber
                  }
                  onBlur={formik.handleBlur}
                />
              )}
            />

            <TextField
              className="to-number"
              placeholder="Calling TO Number"
              variant="outlined"
              sx={{ bgcolor: "white" }}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              id="outlined-basic"
              value={formik.values.toNumber}
              error={formik.touched.toNumber && Boolean(formik.errors.toNumber)}
              helperText={formik.touched.toNumber && formik.errors.toNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="toNumber"
            />

            <Button fullWidth variant="contained" color="error" type="submit">
              Request for a Call
            </Button>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: "100%" }}
              >
                {alertMsg}
              </Alert>
            </Snackbar>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
}

export default CallNumber;
