import {
  Button,
  Card,
  CardContent,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

function CallSubscription(props) {
  const { country, open, onClose, scollToContact } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Card className="card-subscription" elevation={0}>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack gap={2} direction="column">
            <IconButton
              disableFocusRipple
              disableRipple
              sx={{ position: "absolute", top: "0px", right: "0px" }}
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5">Calling Subscription</Typography>

            <Stack
              direction="row"
              sx={{
                width: "100%",
                alignSelf: "center",
                border: "1px solid red",
                padding: "20px",
              }}
              spacing={10}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: "center",
                  columnGap: "4px",
                }}
              >
                <span className={country.className}> </span>
                {country.label}
              </Stack>
              <Typography>{country.cost}</Typography>
            </Stack>

            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={scollToContact}
            >
              Get offer
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}

export default CallSubscription;
