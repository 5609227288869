import * as yup from "yup";

export const initialValues = {
  toNumber: "",
  fromNumber: '',
};

export const validationSchema = yup.object({
  toNumber: yup

    .string("Only Number Allowed")

    .required("To number is required")
    .min(7, "Enter minimum 7 digits")
    .max(15, "Enter maximum 15 digits")
    .matches(
      /^[0-9]+$/,
      "0-9 Numbers Only"
    ),
  fromNumber: yup
  // .mixed("Select number")
  .object().required("From number is required")
  // .required("Select number"),
});
