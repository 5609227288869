import React, { useContext, useEffect, useState } from "react";
import Main from "../Pages/Layout/Main";
import { Navigate } from "react-router-dom";
import { LoginContext } from "../App";

const RequiredAuth = () => {
  if (localStorage.getItem("isLoggedIn")) {
    return <Main />;
  } else {
    return <Navigate to="/" />;
  }
};

export default RequiredAuth;
