import * as yup from "yup";

export const initialValues = {
  email: "",
  password: "",
};

export const validationSchema = yup.object({
  email: yup
  .string("Enter Email")
  .required("Email Is Required"),
  
  // .string().email("Enter valid email"),
 
  password: yup
    .string("Enter Password")
    .required("Password is required")
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/,
    //   "Password must be greater than 6 characters and contain at least one uppercase ,one lowercase ,one number & no space"
    // ),
});