import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  borderRadius: 50,
  // p: 4,
};
const currentStatus = [
  "Caller phone ringing","Caller phone answered","Destination ringing","Call Request Completed",
];
function Callstatus({ open, onClose, values }) {
  const [progress, setProgress] = React.useState(0);
  const [currMessage, setcurrMessage] = useState("")
  

  useEffect(() => {
    let timer;
    if (open) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer);
            onClose();
            return 0;
          }
          
          return oldProgress + 2;
        });
        
      }, 500);
    }

    return () => {
      setProgress(0);
      clearTimeout(timer);
    };
  }, [open]);
  

  return (
    
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{borderRadius: 10}}
      >
        <Box sx={style}>
          
              <LinearProgress
                variant="determinate" value={progress} color="error"
                sx={{height: 20, color:"#d32f2f", borderRadius: 10}}
              />
              <Typography
                sx={{
                  position: "absolute",
                  color: "white",
                  top: 0,
                  right: "20%",
                  transform: "translateX(-50%)",
                }}>
                Trying to connect...
              </Typography>
        </Box>
       
      </Modal>

  );
}

export default Callstatus;
