import { Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

function Contact() {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Get the image element's bounding rectangle
      const image = document.getElementById("contactUS");
      const imageRect = image.getBoundingClientRect();

      // Check if the image is in the viewport
      if (imageRect.top < window.innerHeight && imageRect.bottom >= 0) {
        setAnimated(true);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Stack
      id="contactUS"
      direction={{ sm: "column", md: "row" }}
      spacing={20}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        background: "#100e26",
        color: "white",
        padding: "50px",
        rowGap: "20px",
        borderTop: "0.2px solid red",
      }}
    >
      <Stack
        spacing={4}
        sx={{
          transform: animated ? "translateX(0)" : "translateX(-10%)",
          transition: "transform 1s ease",
          overflowX: "hidden",
        }}
      >
        <Stack>
          <Typography variant="h5">CONTACT US NOW</Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography sx={{ color: "red" }}>Head Quarter Office</Typography>
          <Typography>800 S. Hope St., Los Angeles, CA 90017</Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography sx={{ color: "red" }}>Contact Number</Typography>
          <Stack>
            <Typography>From USA/Canada: 1-800-750-7280</Typography>
            <Typography>From outside USA: +1-818-456-5901</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          transform: animated ? "translateX(0)" : "translateX(10%)",
          transition: "transform 1s ease",
          overflowX: "hidden",
        }}
      >
        <div className="map">
          <iframe
            src="https://maps.google.com/maps?q=800 S. Hope St., Los Angeles, CA 90017&t=&z=10&ie=UTF8&iwloc=&output=embed"
            title="map"
            target="_blank"
            height="250px"
            width="450px"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />
        </div>
      </Stack>
      {/* </Stack> */}
    </Stack>
  );
}

export default Contact;
