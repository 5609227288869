import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import HomeContent from "./HomeContent";
import CallSubscription from "../Subscription/CallSubscription";

const Home = () => {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimated(true);
    }, 500);
  }, []);

  const country = [
    { label: "Turkey", cost: " 0.099 Per Min", value: "Turkey", className: "Turkey" },
    { label: "Iran", cost: " 0.099 Per Min", value: "Iran", className: "Iran" },
    { label: "Nigeria", cost: "0.079 Per Min", value: "Nigeria", className: "Nigeria" },
    { label: "Yemen", cost: " 0.099 Per Min", value: "Yemen", className: "Yemen" },
    { label: "Afghanistan", cost: " 0.099 Per Min", value: "Afghanistan", className: "Afghanistan" },
  ];
  const inputRootStyle = {
    borderRadius: "50px",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
  };
  const [open, setopen] = useState(false);
  const [countrystate, setCoutrystate] = useState({ label: "", cost: "" });
  const [selectedCountry, setselectedCountry] = useState("");
  const handleClose = () => {
    setopen(false);
    setselectedCountry("");
  };

  const scollToContact = () => {
    setTimeout(() => {
      const element = document.getElementById("contactUS");

      element.scrollIntoView({
        behavior: "smooth",
      });
    }, 100);
    setopen(false);
    setselectedCountry("");
  };
  return (
    <div
      className={`parentContainer ${!animated ? "blurredContent" : ""}`}
      id={"home"}
    >
      <CallSubscription
        country={countrystate}
        open={open}
        onClose={handleClose}
        scollToContact={scollToContact}
      />

      <div className="container">
        <div className="Heading1">OneClick International Calling</div>
        <div className="countryList">
          <Autocomplete
            blurOnSelect={true}
            disablePortal
            sx={{ width: { xs: "300px", sm: "500px", xl: "640px" } }}
            options={country}
            
            value={selectedCountry}
            renderOption={(props, option) => (
              <li {...props}
                style={{
                  display: "flex",
                  columnGap: "4px",
                  alignItems: "center",
                }}
              >
                
                <span className={option.className}></span> {option.label}
              </li>
            )}
            onChange={(e, value) => {
              setselectedCountry(value?.label ? value.label : "");
              setCoutrystate({ label: value?.label, cost: value?.cost, className: value?.className });
              value?.label ? setopen(true) : setopen(false);
            }}
            selectOnFocus
            renderInput={(params) => (
             
              <TextField
                sx={{ width: { xs: "100%" } }}
                InputLabelProps={{ shrink: true }}
                {...params}
                placeholder="Where do you want to call?"
                InputProps={{
                  ...params.InputProps,
                  style: inputRootStyle,
                  value: selectedCountry,
                  
                }}
              />
            )}
          />
        </div>
        <div className="titleDest">
          <div className="description">Popular destination: </div>
          <div className="countryName">
          <div style={{display:"flex",alignItems:"center",columnGap:"4px"}}><span className="Iran"/>Iran</div>
            <div style={{display:"flex",alignItems:"center",columnGap:"4px"}}><span className="Turkey"/>Turkey</div>
            <div style={{display:"flex",alignItems:"center",columnGap:"4px"}}><span className="Yemen"/>Yemen</div>
          </div>
        </div>
      </div>
      <div className="wrapperHomeContent">
        <HomeContent />
      </div>
    </div>
  );
};

export default Home;
