import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Button from "@mui/material/Button";

import headerImg from "../../media/logo.jpg";
import { IconButton, Stack } from "@mui/material";
import logoutIcon from "../../media/logout.png";
import Login from "../Login/Login";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';


function Header() {
  const [headerStyle, setHeaderStyle] = useState({
    backgroundColor: "transparent",
  });
  const [open, setopen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const openMenu = Boolean(anchorEl);
  const [isMobile, setIsMobile] = useState(false);

  const handleClose = () => {
    setopen(false);
  };
  // menu
  const handleMenuClick = (event) => {

    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const listenScrollEvent = () => {
    if (window.scrollY > 100) {
      setHeaderStyle({
        backgroundColor: "white",
        color: "black",
        shadow: "none",
      });
    } else {
      setHeaderStyle({ backgroundColor: "transparent" });
    }
  };

  const path = window.location.pathname === "/makeCallRequest";
  const isLoggedInVar = localStorage.getItem("isLoggedIn");

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);
  // media query

  useEffect(() => {




    const mobileMediaQuery = window.matchMedia("(max-width: 768px)"); // Adjust the width as needed

    // Initial check
    setIsMobile(mobileMediaQuery.matches);

    // Listen for changes in the media query
    const handleDeviceChange = (event) => {
      setIsMobile(event.matches);
    };

    mobileMediaQuery.addEventListener("change", handleDeviceChange);
    // Clean up the event listener
    return () => {
      mobileMediaQuery.removeEventListener("change", handleDeviceChange);
    };
  }, []);
  const onNameClick = () => {
    if (!path) {

      navigate("/makeCallRequest");
      handleMenuClose();
    }
  };
  const onLogoClick = () => {

    if (path) {
      navigate("/home");
    }
  };
  const onLogout = () => {
    localStorage.clear();
    navigate("/home");
    handleMenuClose();
  }

  return (
    <div className="headerScroll">
      <Login open={open} onClose={handleClose} />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={headerStyle} elevation={0}>
          <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
            {/* {isLoggedInVar && (
              <div
                className="leftNameContainer"
                onClick={onNameClick}
                style={{
                  position: "absolute",
                  left: "20px",
                  cursor: "pointer",
                }}
              >
                {`${JSON.parse(localStorage.getItem("userData")).fname}`}
              </div>
            )} */}
            <Stack
              justifyContent={"center"}
              onClick={onLogoClick}
              sx={{ cursor: "pointer" }}
            >
              <img alt="logo" src={headerImg} className="logoContainer" />
            </Stack>
            {!isLoggedInVar ? (
              <Button
                color="inherit"
                className="login-btn"
                onClick={() => {
                  setopen(true);
                }}
              >
                Log in
              </Button>
            ) : (
              <div
                className="headerRightContainer"
                style={{
                  position: "absolute",
                  right: "20px",
                  cursor: "pointer",
                }}
              >

                {isMobile ? <IconButton id="basic-button"  onClick={handleMenuClick} aria-controls={openMenu ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}>
                  <AccountCircleOutlinedIcon fontSize="large" sx={{color:"white"}}/>
                </IconButton> :
                  <Button sx={{ color: 'white' }} variant="text" id="basic-button"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleMenuClick}> {`${JSON.parse(localStorage.getItem("userData")).fname
                      } ${JSON.parse(localStorage.getItem("userData")).lname}`}</Button>}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {
                    isMobile && <MenuItem onClick={onNameClick}>{`${JSON.parse(localStorage.getItem("userData")).fname
                      } ${JSON.parse(localStorage.getItem("userData")).lname}`}</MenuItem>
                  }

                  {!isMobile && <MenuItem onClick={onNameClick}>Call request</MenuItem>}
                  <a href="http://portal.gooya.net/index2.php" rel="noreferrer" style={{ textDecoration: 'none', color: 'black' }} target="_blank"><MenuItem onClick={handleMenuClose}>Account Management</MenuItem></a>
                  <MenuItem onClick={onLogout}>Logout</MenuItem>
                </Menu>

                {/* <div className="rightNameContainer" onClick={onNameClick}>{`${JSON.parse(localStorage.getItem("userData")).fname
                  } ${JSON.parse(localStorage.getItem("userData")).lname}`}</div> */}
                {/* <div
                  onClick={() => {
                    localStorage.clear();
                    navigate("/home");
                  }}
                >
                  <Tooltip title="Logout" placement="bottom">
                    <img
                      src={logoutIcon}
                      alt="logout"
                      className="logout-icon"
                    ></img>
                  </Tooltip>
                </div> */}
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}

export default Header;
