import React, { useEffect, useState, useContext, createContext, useLayoutEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Home from "../Pages/Home/Home";
import IsLoggedIn from "./IsLoggedIn";
import RequiredAuth from "./RequiredAuth";
import Offers from "../Pages/Offers/Offers";
import HomeContent from "../Pages/Home/HomeContent";
import Callfamily from "../Pages/CallFamily/Callfamily";
import Contact from "../Pages/Contact/Contact";
import CallNumber from "../Pages/CallNumber/CallNumber";
import Footer from "../Pages/Layout/Footer";
import { LoginContext } from "../App";

const Approutes = () => {
  
  

  const router = createBrowserRouter([
    {
      element: <IsLoggedIn  />,
      children: [
        {
          path: "/",
          element: (
            <>
              <div className="imageWrapper">
                <Home />
              </div>
              <div className="homeContent">
                <HomeContent />
              </div>
              <Offers />
              <Callfamily />
              {/* <Rating/> */}
              <Contact />
              <Footer />
            </>
          ),
        },
        {
          path: "/*",
          element: (
            <Navigate to="/" />
          ),
        },
      ],
    },
    {
      element: <RequiredAuth />,
      children: [
        {
          path: "/home",
          element: (
            <>
              <div className="imageWrapper">
                <Home />
              </div>
              <div className="homeContent">
                <HomeContent />
              </div>
              <Offers />
              <Callfamily />
              {/* <Rating/> */}
              <Contact />
              <Footer />
            </>
          ),
        },
        {
          path: "/makeCallRequest",
          element: <CallNumber />,
        },
      ],
    },
  ]);
  return (
   
      <RouterProvider router={router} />
    
  );
};

export default Approutes;
